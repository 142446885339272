import React from 'react';
import MCPageHeader from '../../../components/magicrop/MCPageHeader';
import { Helmet } from "react-helmet"

function Magicrop(props) {
    

    return <div className="magicrop-body">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Video Crop editor app for Facebook videos</title>
            <link rel="canonical" href="https://fabio.santo.dev/magicrop-dynamic-cropping-video-editor-mac-software" />
            </Helmet>
            <MCPageHeader>
                <div className="container p-10">
                    <div className="font-bold text-4xl">Free Video Crop Editor for Facebook Videos</div>
                    <p>Magicrop is a Mac and iPad app wich helps you crop your video in many format size.</p>
                    <p>1:1 for Facebook posts, 16:9 for Facebook full screen mobile screen size are just some of the many format you can export your video to.</p>
                    <p>Additionally with Magicrop it's easy to track subjects in motion like a surfer riding a wave. Instead of cutting out the subject out of the frame, simply drag and drop the red frame following your subject.</p>
                    <p>Magicrop will crop your video following the movement of your subject.</p>
                    <p>You can download Magicrop for FREE from the iPad App Store or from the Mac App Store</p>
                </div>

            </MCPageHeader>
        </div>
}

export default Magicrop